import create from "zustand"

import { IUser } from "../types/common"

type Store = {
  authUser: IUser | null
  globalLoading: boolean
  breadCrumb: string
  onboardingStep: number
  taxModalOpen: boolean
  taxModalTitle: string
  taxModalText: string
  setAuthUser: (user: IUser | null) => void
  setGlobalLoading: (isLoading: boolean) => void
  setBreadCrumb: (breadCrumb: string) => void
  setOnboardingStep: (step: number) => void
  setTaxModalOpen: (taxIdModal: boolean) => void
  setTaxModalTitle: (title: string) => void
  setTaxModalText: (text: string) => void
}

const useStore = create<Store>((set) => ({
  authUser: null,
  globalLoading: false,
  breadCrumb: "",
  onboardingStep: 0,
  taxModalOpen: false,
  taxModalText: "",
  taxModalTitle: "",
  setAuthUser: (user) => set((state) => ({ ...state, authUser: user })),
  setGlobalLoading: (isLoading) => set((state) => ({ ...state, globalLoading: isLoading })),
  setBreadCrumb: (breadCrumb) => set((state) => ({ ...state, breadCrumb: breadCrumb })),
  setOnboardingStep: (step) => set((state) => ({ ...state, onboardingStep: step })),
  setTaxModalOpen: (taxIdModal) => set((state) => ({ ...state, taxModalOpen: taxIdModal })),
  setTaxModalTitle: (title) => set((state) => ({ ...state, taxModalTitle: title })),
  setTaxModalText: (text) => set((state) => ({ ...state, taxModalText: text })),
}))

export default useStore
