import { AuthAction, useAuthUser, withAuthUser } from "next-firebase-auth"
import React, { useEffect } from "react"
import useStore from "store"
import { trpc } from "utils/trpc"

import { IUser } from "types/common"

interface AuthGuardProps {
  children: JSX.Element
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const store = useStore()
  const authUser = useAuthUser()
  const setLoading = store.setGlobalLoading

  const query = trpc.useQuery(["user.me"], {
    // enabled: session.status === "authenticated",
    enabled: authUser.clientInitialized && !!authUser.id,
    retry: 1,
    select: (data) => data,
    onSuccess: (data) => {
      store.setAuthUser(data as IUser)

      setLoading(false)
    },
    onError: (error) => {
      console.log(error)

      setLoading(false)
    },
  })

  useEffect(() => {
    if (query.isLoading) {
      setLoading(true)
    }
  }, [query.isLoading, setLoading])

  return children
}

export default withAuthUser<AuthGuardProps>()(AuthGuard)
