// There is an issue with TRPC and i18next so that is why TS i turend off here.
// @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { withTRPC } from "@trpc/next"
import { getAuth } from "firebase/auth"
import AuthGuard from "guards/AuthGuard"
import { appWithTranslation } from "next-i18next"
import type { AppType } from "next/dist/shared/lib/utils"
import { Toaster } from "react-hot-toast"
import superjson from "superjson"
import getBaseUrl from "utils/getBaseUrl"

import { initAuth } from "lib/firebase/initAuth"

import type { AppRouter } from "../server/router"
import "../styles/globals.css"

initAuth()

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} gutter={8} />
      <AuthGuard>
        <Component {...pageProps} />
      </AuthGuard>
    </>
  )
}

const TranslatedApp = appWithTranslation(MyApp)

export default withTRPC<AppRouter>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    const url = `${getBaseUrl()}/api/trpc`

    return {
      url,
      transformer: superjson,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
      async headers() {
        const auth = getAuth()
        const token = await auth.currentUser?.getIdToken()

        return {
          Authorization: `Bearer ${token}`,
        }
      },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
})(TranslatedApp)
