import { getApps } from "firebase/app"
import { getAuth } from "firebase/auth"
import { InitConfig, init } from "next-firebase-auth"

export const initAuth = () => {
  const config: InitConfig = {
    // debug: true,

    authPageURL: "/auth",
    appPageURL: "/",

    loginAPIEndpoint: "/api/login",
    logoutAPIEndpoint: "/api/logout",

    onLoginRequestError: (err) => {
      console.error(err)
    },
    onLogoutRequestError: (err) => {
      console.error(err)
    },

    // firebaseAuthEmulatorHost: 'localhost:9099',

    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.FIREBASE_ADMIN_PROJECT_ID ?? "",
        clientEmail: process.env.FIREBASE_ADMIN_CLIENT_EMAIL ?? "",
        // The private key must not be accessible on the client side.
        privateKey: process.env.FIREBASE_ADMIN_PRIVATE_KEY?.replace(/\\n/g, "\n") ?? "",
      },
      databaseURL: "",
    },

    // Use application default credentials (takes precedence over firebaseAdminInitConfig if set)
    // useFirebaseAdminDefaultCredential: true,
    firebaseClientInitConfig: {
      projectId: process.env.FIREBASE_ADMIN_PROJECT_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? "",
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? "",
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? "",
      // storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      // messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    },

    cookies: {
      name: "myne-onboarding", // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [process.env.COOKIE_SECRET_CURRENT, process.env.COOKIE_SECRET_PREVIOUS],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure: true, // set this to false in local (non-HTTPS) development
      signed: true,
    },

    onVerifyTokenError: (err) => {
      console.error(err)
    },
    onTokenRefreshError: (err) => {
      console.error(err)
    },
  }

  init(config)

  // const auth = getAuth()

  // auth.useDeviceLanguage()
}
